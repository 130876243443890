import React from 'react'
import styled from 'styled-components'

import SEO from '@/components/seo'
import CalculatorStructure from '@/components/CalculatorStructure'
import { PayPal } from '@/components/Calculator/Ecommerce'

import { CALCULATORS } from '@/helpers/mocks/calculators'
import SectionInfoCard from './SectionInfoCard'

const StyledPayPalCalculator = styled(PayPal)`
  min-width: unset;
  max-width: unset;
`

const Paypal = () => (
  <>
    <SEO title="Paypal" />
    <CalculatorStructure
      section="finance-calculators"
      headerTitle="finance"
      title={CALCULATORS['finance-calculators'].paypal.title}
      description={CALCULATORS['finance-calculators'].paypal.description}
      calculatorNode={<StyledPayPalCalculator />}
      asideCards={<SectionInfoCard />}
    />
  </>
)

export default Paypal
